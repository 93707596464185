<template>
  <div class="login-cont">
    <div class="logo-bg">
      <img src="@/assets/image/logo2.png" class="logo" alt="">
    </div>

    <div class="form-out">
      <div class="form-cont">
        <div class="form-header">
          <div class="form-h-name">
            <span>登录</span>
          </div>
          <div class="form-h-line"></div>
        </div>
        <div class="form-item">
          <div class="icon">
            <phone theme="two-tone" :fill="['#333' ,'#1F955B']"/>
          </div>
          <div class="input">
            <input v-model="form.mobile" type="text" placeholder="请输入您的手机号码">
          </div>
        </div>
        <div class="form-item">
          <div class="icon">
            <protect theme="two-tone" :fill="['#333' ,'#1F955B']"/>
          </div>
          <div class="input">
            <input v-model="form.code" type="text" placeholder="输入验证码">
          </div>
          <div class="btn">
            <div class="button" @click="phoneCode">
              <span v-show="num <= 0">获取验证码</span>
              <span v-show="num > 0" class="red">{{num}}</span>
            </div>
          </div>
        </div>
        

        <div class="form-sub" @click="phoneLogin">立即登录</div>
      </div>
    </div>

    <div class="form-footer">
      <div class="form-check">
        <div class="icon" :class="{'on': readyHide}" @click="checkHide">
          <div class="forn-check"></div>
        </div>
        <div class="text">
          已阅读并同意
          <!-- <router-link to="/explainHide">《隐私条款》</router-link> -->
          <router-link to="/explainAgreement">《大诚保用户使用协议》</router-link>
        </div>
      </div>
    </div>

  
  </div>
</template>

<script>
import {Phone, Protect} from '@icon-park/vue'
export default {
  name: 'login',
  components: {
    Phone,
    Protect,
  },
  data () {
    return {
      code: this.$route.query.code,
      logout: this.$route.query.logout, // 注销登录
      num: 0, // 验证码倒计时
      form: {
        mobile: '',
        code: '',
      },
      readyHide: false,
    }
  },
  created () {
    this.$store.commit('updateLoader', true);

    // 验证code 
    if(! this.code){
      // 清除缓存信息
      this.$utils.removeCache('pass');
      this.$init.pass = {
        openId: '',
        phone: '',
        token: '',
      }
      // 获取微信登录code
      this.getCode()
    }else{
      if(this.$init.pass.token == '' && (! this.$utils.getCache('logout'))){
        this.wxGetOpenId()
      }
      this.$store.commit('updateLoader', false);
    }
  },
  methods: {
    /**
     * 阅读隐私条款
     */
    checkHide () {
      this.readyHide ? this.readyHide = false : this.readyHide = true;
    },
    /**
     * 发送验证码
     */
    phoneCode () {
      const vm = this;
      // 阻止刷
      if(vm.num > 0){
        vm.$createToast({
          txt: '请稍后再试',
          type: 'warn'
        }).show()
        return
      }
      // 倒计时
      vm.num = 60;
      let sNum = setInterval(() => {
        vm.num -= 1;
        if(vm.num == 1){
          clearInterval(sNum)
        }
      }, 1000);
      // 请求服务
      let postData = {
        "mobile": this.form.mobile,
      }
      if(! this.$utils.isPhone(this.form.mobile + '')){
        vm.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post('/user/sms/mobileLoginCode', postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 登录
     */
    phoneLogin () {
      const vm = this;
      if(! this.$utils.isPhone(this.form.mobile + '')){
        vm.$createToast({
          txt: '手机号格式错误',
          type: 'warn'
        }).show()
        return
      }else if(this.form.code == ''){
        vm.$createToast({
          txt: '请输入验证码',
          type: 'warn'
        }).show()
        return
      }else if(! this.readyHide){
        vm.$createToast({
          txt: '请查看隐私条款',
          type: 'warn'
        }).show()
        return
      }
      let postData = {
        // openId: this.$init.pass.openId,
        mobile: this.form.mobile,
        code: this.form.code,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/sms/login", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            vm.$utils.removeCache('logout');
            vm.$init.pass.phone = vm.form.mobile;
            vm.$init.pass.token = response.headers.token;
            if(response.data.body.nickname){
              vm.$init.pass.headimg = response.data.body.headimg;
              vm.$init.pass.nickname = response.data.body.nickname;
            }

            if(vm.$utils.isWeixin()){
              // 微信环境绑定openId
              vm.bindUserWxppOpenId();
            }else{
              // 非微信环境跳转登录
              vm.passCheck()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
            vm.$store.commit('updateLoader', false);
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 绑定微信ID
     */
    bindUserWxppOpenId () {
      const vm = this;
      let postData = {
        openId: this.$init.pass.openId,
      }
      this.$http
        .post("/user/user/bindUserWxppOpenId", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            if(response.data.body.nickname){
              vm.$init.pass.headimg = response.data.body.headimg;
              vm.$init.pass.nickname = response.data.body.nickname;
            }
            vm.passCheck()
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 获取code
     */
    getCode () {
      // 非微信环境 - 阻断
      if(! this.$utils.isWeixin()){
        this.$store.commit('updateLoader', false);
        return;
      }

      // openId
      // let hrefStr = `
      //   https://open.weixin.qq.com/connect/oauth2/authorize?
      //   appid=${this.$init.appId}
      //   &redirect_uri=${encodeURIComponent(window.location.href)}
      //   &response_type=code
      //   &scope=snsapi_base
      //   &state=json#wechat_redirect
      // `.replace(/\s*/g,"");

      // openId + 用户头像 + 昵称
      let hrefStr = `
        https://open.weixin.qq.com/connect/oauth2/authorize?
        appid=${this.$init.appId}
        &redirect_uri=${encodeURIComponent(window.location.href)}
        &response_type=code
        &scope=snsapi_userinfo
        &state=json#wechat_redirect
      `.replace(/\s*/g,"");
      window.location.href = hrefStr
    },
    /**
     * 获取微信openId
     */
    wxGetOpenId () {
      const vm = this;
      let postData = {
        "appId": this.$init.appId,
				"code": this.code,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/user/wxpplogin/queryOpenId", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            vm.$init.pass.openId = response.data.body.openId;
            vm.wxLogin()

            // vm.$init.pass.phone = response.data.body.mobile;
            // vm.$init.pass.token = response.data.body.tokenHeaderValue;
            // if(! vm.$init.pass.phone){
            //   vm.$store.commit('updateLoader', false);
            // }else{
            //   vm.passCheck()
            // }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 微信登录
     */
    wxLogin () {
      const vm = this;
      let postData = {
        "openId": this.$init.pass.openId,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/wxpp/login", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            if(response.data.body.mobile){
              // 登录成功 - 跳转登录
              vm.$init.pass.token = response.headers.token;
              vm.$init.pass.phone = response.data.body.mobile;
              if(response.data.body.nickname){
                vm.$init.pass.headimg = response.data.body.headimg;
                vm.$init.pass.nickname = response.data.body.nickname;
              }
              vm.passCheck()
            }else{
              // 登录失败 - 跳转手机号登陆
              vm.$store.commit('updateLoader', false);
            }

            // vm.$init.pass.phone = response.data.body.mobile;
            // vm.$init.pass.token = response.data.body.tokenHeaderValue;
            // if(! vm.$init.pass.phone){
            //   vm.$store.commit('updateLoader', false);
            // }else{
            //   vm.passCheck()
            // }
          } else {
            vm.$store.commit('updateLoader', false);
            // vm.$createToast({
            //   txt: response.data.header.msg,
            //   type: 'warn'
            // }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 登录后跳转
     */
    passCheck () {
      this.$store.commit('updateLoader', true);
      this.$utils.setCache('pass', this.$init.pass);
      var loginBack = this.$utils.getCache('loginBack');
      if(loginBack){
        this.$utils.removeCache('loginBack');
        if(loginBack == '/user'){
          this.$router.replace('/home')
        }
        this.$router.replace(loginBack)
      }else{
        this.$router.replace('/home')
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.red{
  color: red;
}
.login-cont{
  position: relative;
  min-height: 100.1vh;
  // background-image: url(../assets/image/bg1.png);
  // background-size: 100% auto;
  // background-position: 0 0;
  // background-repeat: no-repeat;
  // box-sizing: border-box;
  // padding-top: 196px;
}
.logo-bg{
  width: 100%;
  height: 400px;
  background-color: #0c945a;
  box-sizing: border-box;
  padding-top: 30px;
}
.logo{
  display: block;
  width: 240px;
  height: 240px;
  margin: 0 auto;
}

.form-out{
  position: relative;
  height: 460px;
}
.form-cont{
  width: 650px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.04);
  padding: 36px;
  padding-bottom: 115px;
  position: absolute;
  top: -116px;
  left: 50px;
}
.form-item{
  display: flex;
  margin-bottom: 22px;
  border-bottom: 1px solid #F4F4F4;
  align-content: center;
  justify-content: center;
  align-items: center;
  .icon{
    // padding-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 36px;
    text-align: center;
  }
  .input{
    flex: 10;
    padding: 0;
    input{
      display: block;
      width: 100%;
      padding: 22px 0;
      font-size: 32px;
      line-height: 60px;
      outline: none;
    }
  }
  .btn{
    padding: 22px 0;
    margin-left: 20px;
  }
  .button{
    width: 140px;
    height: 60px;
    font-size: 24px;
    text-align: center;
    line-height: 60px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DCDCDC;
  }
}
.form-sub{
  width: 410px;
  height: 100px;
  background: #1F955B;
  border-radius: 50px;
  text-align: center;
  font-size: 32px;
  line-height: 100px;
  color: #fff;
  position: absolute;
  bottom: -50px;
  left: 50%;
  margin-left: -205px;
}

.form-check{
  display: flex;
  padding-top: 10px;
  .icon{
    padding-right: 24px;
    padding-top: 2px;
    .forn-check{
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      border: 2px solid #CBCBCB;
      border-radius: 2px;
    }
    &.on{
      .forn-check{
        background-color: #1F955B;
        border-color: #1F955B;
      }
    }
  }
  .text{
    height: 36px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
  }
}

.form-header{
  font-size: 48px;
  height: 60px;
  line-height: 60px;
  position: relative;
  margin-bottom: 30px;
  .form-h-name{
    height: 60px;
    line-height: 60px;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 120px;
    text-align: center;
    font-weight: 700;
  }
  .form-h-line{
    position: absolute;
    height: 20px;
    width: 120px;
    background-color: #1F955B;
    left: 0;
    bottom: 0;
    z-index: 5;
    border-radius: 20px;
  }
}
.form-footer{
  padding: 0 0 0 60px;
}
</style>